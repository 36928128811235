<template>
  <div class="view h-100">
    <div class="strip-bg strip-bg--condensed strip-bg--blue">
      <div class="container pos-r flex space-between h-100 valign-center" style="min-height: 7rem;">
        <h2 class="font--biggest h-100 m-0 color-white">
          Zeitauswertung
        </h2>

        <DropdownTrackingResultActions :results="trackingResults.data" style="max-width: 10rem;" />
      </div>
    </div>

    <div class="container pos-r">
      <el-row :gutter="20" type="flex" style="top: -2rem;">
        <el-col :xs="24" :sm="17">
          <el-card>
            <TableTrackingResults :data="trackingResults.data" />
          </el-card>
        </el-col>

        <el-col :xs="24" :sm="7">
          <div class="aside aside--sticky">
            <el-card :body-style="{ padding: '0' }">
              <SumWorkHours :hours="trackingResults.meta.total_hours" :income="trackingResults.meta.total_income" />
            </el-card>

            <el-card class="m-t-1">
              <FormFilterTimeTracking />
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import DropdownTrackingResultActions from '@/components/Button/DropdownTrackingResultActions.vue';
import FormFilterTimeTracking from '@/components/Form/FormFilterTimeTracking.vue';
import SumWorkHours from '@/components/SumWorkHours.vue';
import TableTrackingResults from '@/components/Table/TableTrackingResults.vue';

export default {
  name: 'Tracking',

  components: {
    DropdownTrackingResultActions,
    FormFilterTimeTracking,
    SumWorkHours,
    TableTrackingResults
  },

  computed: {
    trackingResults() {
      return this.$store.state.tracking.results;
    }
  }
};
</script>

<style lang="scss" scoped>
.aside {
  &--sticky {
    position: sticky;
    top: 1rem;
  }
}
</style>
