<template>
  <div class="view h-100">
    <div class="strip-bg strip-bg--condensed strip-bg--blue">
      <div class="container pos-r valign-center" style="min-height: 7rem;">
        <h2 class="font--biggest h-100 m-0 color-white">
          Reiseauswertung
        </h2>
      </div>
    </div>

    <div class="container pos-r">
      <el-row :gutter="20" type="flex" style="top: -2rem;">
        <el-col :xs="24" :sm="17">
          <el-card>
            <TableTravelResults :data="travelingResults" />
          </el-card>
        </el-col>

        <el-col :xs="24" :sm="7">
          <div class="aside aside--sticky">
            <el-card>
              <FormFilterTraveling />
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import FormFilterTraveling from '@/components/Form/FormFilterTraveling.vue';
import TableTravelResults from '@/components/Table/TableTravelResults';

export default {
  name: 'Travel',

  components: {
    FormFilterTraveling,
    TableTravelResults
  },

  computed: {
    travelingResults() {
      return this.$store.state.travel.all;
    }
  }
};
</script>

<style lang="scss" scoped>
.aside {
  &--sticky {
    position: sticky;
    top: 1rem;
  }
}
</style>
