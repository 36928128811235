<template>
  <el-form
    :model="form"
    ref="form"
    :rules="rules"
    @submit.native.prevent="onSubmitFilter"
    label-position="left"
    label-width="80px"
    class="form__filter-time-tracking w-100"
  >
    <!-- Project -->
    <el-form-item label="Projekt">
      <div class="flex">
        <select v-model="form.project" class="w-80">
          <option :value="project.id" v-for="project in projects" :key="project.id">
            <span v-html="project.name"></span>
          </option>
        </select>

        <el-button class="m-l-half" type="default" plain icon="el-icon-delete" @click="form.project = 0"></el-button>
      </div>
    </el-form-item>

    <!-- Tag -->
    <el-form-item label="Aufgabe">
      <div class="flex">
        <select v-model="form.tag" class="w-100">
          <option :value="tag.id" v-for="tag in tags" :key="tag.id">
            <span v-html="tag.name"></span>
          </option>
        </select>

        <el-button class="m-l-half" type="default" plain icon="el-icon-delete" @click="form.tag = 0"></el-button>
      </div>
    </el-form-item>

    <!-- Company -->
    <el-form-item label="Firma">
      <div class="flex">
        <select v-model="form.company" class="w-80">
          <option :value="company.id" v-for="company in companies" :key="company.id">
            <span v-html="company.name"></span>
          </option>
        </select>

        <el-button class="m-l-half" type="default" plain icon="el-icon-delete" @click="form.company = 0"></el-button>
      </div>
    </el-form-item>

    <!-- Start/End -->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="12">
        <el-form-item label="Start">
          <el-input v-model="form.start" type="date" placeholder="dd.MM.YYYY"></el-input>
        </el-form-item>
      </el-col>

      <el-col :xs="24" :sm="12">
        <el-form-item label="Ende">
          <el-input v-model="form.end" type="date" placeholder="dd.MM.YYYY"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-switch v-model="form.billed" active-value="1" inactive-value="0" active-text="Abgerechnet" inactive-text="Offen"></el-switch>

    <!-- Submit -->
    <div class="m-t-3 m-b-0">
      <el-button type="primary" native-type="submit" :loading="loading" class="w-100">Filtern</el-button>
      <el-button type="default" plain @click="clearFilter" class="w-100 m-t-half" style="margin-left: 0;">Filtern löschen</el-button>
    </div>
  </el-form>
</template>

<script>
import { addDays, endOfDay, format, startOfDay, startOfMonth } from 'date-fns';

export default {
  name: 'FormFilterTimeTracking',

  data() {
    return {
      form: {
        billed: 0,
        project: null,
        tag: null,
        company: null,
        start: null,
        end: null
      },
      reportResults: { meta: {} },
      loading: false,
      rules: {
        start: [{ type: 'date', required: true, message: 'Bitte Startdatum eingeben', trigger: 'blur' }],
        end: [{ type: 'date', required: true, message: 'Bitte Enddatum eingeben', trigger: 'blur' }]
      }
    };
  },

  computed: {
    companies() {
      return this.$store.state.company.all;
    },

    filter() {
      return this.$store.state.tracking.filter;
    },

    projects() {
      return this.$store.state.project.all;
    },

    tags() {
      return this.$store.state.tag.all;
    },

    user() {
      return this.$store.state.user.current;
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    clearFilter() {
      this.form.project = null;
      this.form.tag = null;
      this.form.company = null;
      this.form.start = null;
      this.form.end = null;

      this.$store.commit('tracking/CLEAR_FILTERS');
    },

    init() {
      Object.assign(this.form, this.filter);
      if (this.form.start) {
        this.form.start = format(new Date(this.form.start), 'yyyy-MM-dd');
      } else {
        this.form.start = format(startOfMonth(new Date()), 'yyyy-MM-dd');
      }

      if (this.form.end) {
        this.form.end = format(new Date(this.form.end), 'yyyy-MM-dd');
      } else {
        this.form.end = format(addDays(new Date(), 1), 'yyyy-MM-dd');
      }
    },

    onSubmitFilter() {
      const start = this.form.start ? startOfDay(new Date(this.form.start)) : startOfDay(new Date());
      const end = this.form.end ? endOfDay(new Date(this.form.end)) : endOfDay(new Date());

      const DATA = Object.assign({}, this.form);
      DATA.start = start.toISOString(true);
      DATA.end = end.toISOString(true);
      DATA.user = this.user.id;

      this.$store.commit('tracking/SET_FILTERS', DATA);
      this.requestTimeTrackingResults();
    },

    requestTimeTrackingResults() {
      this.loading = true;

      return this.$store.dispatch('tracking/GET_TIMETRACKING_RESULTS').finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>
