<template>
  <el-table :data="data" class="w-100">
    <el-table-column prop="id" label="ID" width="60"></el-table-column>

    <el-table-column prop="date" label="Date">
      <template slot-scope="scope">
        <span>{{ format(new Date(scope.row.date), 'dd.MM.yyyy') }} </span>
      </template>
    </el-table-column>

    <el-table-column prop="company" label="Company">
      <template slot-scope="scope">
        <div v-if="scope.row.company">
          <p class="m-0">{{ scope.row.company.name }}</p>

          <span class="color-gray font--small" v-if="scope.row.company.address"
            >{{ scope.row.company.address.street_nr }}, {{ scope.row.company.address.city }}</span
          >
        </div>
      </template>
    </el-table-column>

    <el-table-column prop="project" label="Project">
      <template slot-scope="scope">
        <span v-if="scope.row.project">{{ scope.row.project.name }}</span>
      </template>
    </el-table-column>

    <el-table-column prop="description" label="Description"></el-table-column>
  </el-table>
</template>

<script>
import { format } from 'date-fns';

export default {
  name: 'TableTravelResults',

  props: {
    data: {
      type: Array,
      required: true
    }
  },

  methods: {
    format
  }
};
</script>
